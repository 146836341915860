@import "minima";

.hero.hero-index {
    background-image: url("/assets/images/main-bg.jpg");
    background: linear-gradient(to right, rgba(108, 122, 137, 0.35), rgba(108, 122, 137, 0.95)), rgba(0, 0, 0, 0.55) url("/assets/images/main-bg.jpg") no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    text-transform: lowercase;
}

.hero.hero-fullscreen-generic {
    background-image: url("/assets/images/5.jpg");
    background: linear-gradient(to right, rgba(108, 122, 137, 0.35), rgba(108, 122, 137, 0.95)), rgba(0, 0, 0, 0.55) url("/assets/images/5.jpg") no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    overflow: hidden;
}

.hero.hero-about {
    background-image: url("/assets/images/3.jpg");
    background: linear-gradient(to right, rgba(108, 122, 137, 0.35), rgba(108, 122, 137, 0.95)), rgba(0, 0, 0, 0.55) url("/assets/images/3.jpg") no-repeat;
    background-position: center top;
    background-attachment: fixed;
    background-size: cover;
}


.hero.hero-reading {
    background-image: url("/assets/images/3.jpg");
    background: linear-gradient(to right, rgba(108, 122, 137, 0.35), rgba(108, 122, 137, 0.95)), rgba(0, 0, 0, 0.55) url("/assets/images/3.jpg") no-repeat;
    background-position: center top;
    background-attachment: fixed;
    background-size: cover;
}

.book-list {
    padding-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
}

@media (max-width: 768px) {
    .book-list {
        display: block;
    }
}

.book-card {
    // flex: 1;
    width: 32%;
}

.projects-section {
    background-image: url("/assets/images/projects-bg.jpg");
    background: linear-gradient(to right, rgba(108, 122, 137, 0.35), rgba(108, 122, 137, 0.95)), rgba(0, 0, 0, 0.55) url("/assets/images/projects-bg.jpg") no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    color: whitesmoke;
}

.blog-section {
    background-image: url("/assets/images/blog-bg.jpg");
    background: linear-gradient(to right, rgba(108, 122, 137, 0.35), rgba(108, 122, 137, 0.95)), rgba(0, 0, 0, 0.55) url("/assets/images/blog-bg.jpg") no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    color: whitesmoke;
}

.blog-post-section {
    background-image: url("/assets/images/blog-bg.jpg");
    background: linear-gradient(to right, rgba(108, 122, 137, 0.35), rgba(108, 122, 137, 0.95)), rgba(0, 0, 0, 0.55) url("/assets/images/blog-bg.jpg") no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    color: whitesmoke;
}

.is-flex-autogrow {
    flex: 1;
}

.hero h1, .hero h2 {
    color: whitesmoke;
}

.navbar {
    font-family: 'Fira Sans', serif;
    text-transform: lowercase;
}

.navbar-brand img {
    border-radius: 50%;
}

.navbar-brand {
    font-family: 'Fira Sans', serif;
    font-weight: bold;
    text-transform: lowercase;
}

.navbar-brand span {
    margin-left: 0.5rem;
}

.hero {
    font-family: 'Fira Sans', serif;
}

.is-small-caps {
    font-variant: small-caps;
}

.smaller-caps {
    text-transform: uppercase;
    font-size: 1.15rem;
}

.disabled-link {
    pointer-events: none;
    cursor: default;
}

.tabs a {
    color: whitesmoke;
}

.tabs a:hover {
    color: lightgray;
}

.navbar a,a:visited {
    color: #4a4a4a;
}

/*.masonry {*/
/*    column-count: 4;*/
/*    column-gap: 1rem;*/
/*}*/

/*.masonry .masonry-item {*/
/*    display: inline-block;*/
/*    margin: 0 0 1rem;*/
/*    width: 100%;*/
/*}*/

.header-tile {
    flex-grow: 0 !important;
}

.header-tile .card {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

.level-left {
    width: 90%;
}

.level-right {
    width: 10%;
}

.level-item {
    max-width: 100%;
}

.hero-projects .card-header-title {
    padding: 1.5rem;
}

.posts-footer-time {
    padding: 0.5rem 0.5rem;
}

.tags {
    padding: 0.5rem 0;
    display: inline !important;
}

.tags .tag {
    margin: 0 0.5rem;
}

figure.highlight {
    text-align: left !important;
}

.navbar-item-current {
    font-weight: bold;
}


.blog-post-section .card-header {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.blog-post-section .card-header>div {
    padding: 0.5rem;
}

.google-slides-container{
    position: static;
    width: 100%;
    height: 100%;
    // padding-top: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.google-slides-container iframe{
    position: absolute;
    top: 1%;
    left: 12.5%;
    width: 75%;
    height: 90%;
}

.hero.hero-fullscreen-generic .hero-body {
    flex-direction: column;
}

.hero.hero-fullscreen-generic .title-section {
    padding: 0.5rem;
    background: white;
}
